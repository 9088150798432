import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TableColumnDefinition } from '@konnektu/components';
import { DestroyService } from '@konnektu/helpers';
import {
  from,
  injectObjectParameters,
  MetastoreService
} from '@konnektu/metastore';
import { TopBarService } from '@konnektu/sidebar-layout';
import { TuiTablePagination } from '@taiga-ui/addon-table';
import { tuiPure } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import {
  catchError,
  combineLatest,
  isObservable,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import {
  ExpandedFolderDto,
  FolderDto,
  FolderService
} from './folders/folder.service';

export interface TableOrder {
  column: string | null;
  direction: 1 | -1;
}

export type SectionDataConstructor<T> = (
  pagination: TuiTablePagination,
  order: TableOrder,
  filter: Record<string, any> | null
) => Observable<{
  items: T[];
  totalCount: number;
}>;

export interface SectionData<T> {
  items: T[];
  totalCount: number;
}

export interface SectionColumnDefinition<T> extends TableColumnDefinition<T> {
  requestOnly?: boolean;
}

@Component({
  selector: 'knk-section',
  templateUrl: 'section.component.html',
  styleUrls: ['section.component.scss'],
  providers: [DestroyService, FolderService]
})
export class SectionV2Component<T> {
  private readonly metastore = inject(MetastoreService);

  private readonly topBarService = inject(TopBarService);

  private readonly folderService = inject(FolderService);

  protected readonly _entityName = signal('User');

  folders$ = toObservable(this.folderService.folderHierarchy);

  @Input() set entityName(val: string) {
    this._entityName.update(() => val);
    this.folderService.entityName.update(() => val);
  }

  @Input() set loading(val: boolean) {
    this._loading.update(() => val);
  }

  @Input() filters: PolymorpheusContent;

  @Input() columns: SectionColumnDefinition<T>[] = [];

  @Input() enableFilter = true;

  @Input() showFolders = true;

  @Input() canUpdateFolder = true;

  @Input() styleOnHover = true;

  @Input() enablePagination = true;

  @Input() set additionalFolderFilter(val: Record<string, any> | null) {
    this.folderService.additionalFilter.update(() => val);
  }

  @Input() paginationMode: 'default' | 'infinite' = 'default';

  @Input() data?: Observable<SectionData<T>> | SectionDataConstructor<T>;

  @tuiPure
  get tableData() {
    if (!this.data) {
      return this.defaultData$;
    }
    if (isObservable(this.data)) {
      return this.data;
    } else {
      const dataFactory$ = this.data;
      return combineLatest([this.pagintion$, this.sort$, this.filter$]).pipe(
        switchMap(([pagintion, sort, filter]) =>
          dataFactory$(pagintion, sort, filter)
        )
      );
    }
  }

  @Input() useQueryWithoutCount = false;

  @Input() enableHover = false;

  @Output() entityClicked: EventEmitter<T> = new EventEmitter<T>();

  @Output() folderChange = new EventEmitter<ExpandedFolderDto | null>();

  @Output() folderCreate = new EventEmitter<any>();

  @Output() folderRemove = new EventEmitter<ExpandedFolderDto>();

  @Output() folderEdit = new EventEmitter<ExpandedFolderDto>();

  protected readonly _loading = signal(false);

  protected sortColumn = signal<string | null>(null);

  protected sortDirection = signal<1 | -1>(1);

  protected readonly _sort = computed(() => ({
    column: this.sortColumn(),
    direction: this.sortDirection()
  }));

  protected readonly sort$ = toObservable(this._sort);

  @Input() set sort(val: TableOrder) {
    this.sortColumn.update(() => val.column);
    this.sortDirection.update(() => val.direction);
  }

  @Output() sortChange = new EventEmitter<TableOrder>();

  protected readonly page = signal(0);

  protected readonly pageSize = signal(20);

  protected readonly _pagination = computed(() => ({
    page: this.page(),
    size: this.pageSize()
  }));

  protected readonly pagintion$ = toObservable(this._pagination);

  @Input() set pagination(val: TuiTablePagination) {
    this.page.update(() => val.page);
    this.pageSize.update(() => val.size);
  }

  @Output() paginationChange = new EventEmitter<TuiTablePagination>();

  protected _filter = signal<Record<string, any> | null>(null);

  protected readonly filter$ = toObservable(this._filter);

  @Input() set filter(val: Record<string, any> | null) {
    this._filter.update(() => val);
  }

  @Output() filterChange = new EventEmitter<Record<string, any> | null>();

  protected hoveredItem = signal<T | null>(null);

  protected readonly activeFolderId = signal<number | null>(null);

  metadataProvider$ = this.metastore
    .metadata({
      ui: { section: `Section:${this._entityName()}` }
    })
    .pipe(shareReplay(1));

  isFilterVisible = this.topBarService.isFilterVisible;

  protected query = computed(() => {
    const query = from(this._entityName())
      .select(
        this.columns
          ? this.columns.filter((c) => !c.isFunctionColumn).map((c) => c.id)
          : []
      )
      .where(this._filter() ?? undefined)
      .paginate(this.page(), this.pageSize());
    const sortColumn = this.sortColumn();
    if (sortColumn) {
      query.orderBy(sortColumn, this.sortDirection() === -1 ? 'desc' : 'asc');
    }
    return query.done();
  });

  protected readonly previousItems = signal<T[]>([]);

  protected defaultData$ = toObservable(this._entityName).pipe(
    tap(() => this._loading.update(() => true)),
    mergeMap((entityName) => {
      const query = from(entityName).select(
        this.columns
          ? this.columns.filter((c) => !c.isFunctionColumn).map((c) => c.id)
          : []
      );
      return this.filter$.pipe(
        mergeMap((filter) => {
          query.where(filter ?? undefined);
          return this.sort$.pipe(
            tap(() => {
              this.page.update(() => 0);
              this.previousItems.update(() => []);
            }),
            mergeMap((sorting) => {
              if (sorting.column) {
                query.orderBy(
                  sorting.column,
                  sorting.direction === -1 ? 'desc' : 'asc'
                );
              }
              return this.pagintion$.pipe(
                mergeMap((pagination) => {
                  query.paginate(pagination.page, pagination.size);
                  if (this.useQueryWithoutCount) {
                    return this.metastore
                      .select<T>(query)
                      .pipe(map((items) => ({ items, totalCount: 0 })));
                  } else {
                    return this.metastore.selectCount<T>(query).pipe(
                      map((res) => ({
                        items: res.Result,
                        totalCount: res.TotalCount
                      }))
                    );
                  }
                }),
                map((result) => {
                  if (this.paginationMode === 'infinite') {
                    this.previousItems.update((prevItems) =>
                      prevItems ? [...prevItems, ...result.items] : result.items
                    );
                    return {
                      ...result,
                      items: this.previousItems()
                    };
                  }
                  return result;
                }),
                tap(() => this._loading.update(() => false)),
                catchError(() => {
                  this._loading.update(() => false);
                  return of({ items: [] as T[], totalCount: 0 });
                })
              );
            })
          );
        })
      );
    })
  );

  get tuiColumns(): readonly string[] {
    return this.columns
      ?.filter((c) => !c.requestOnly && (!!c.header || c.isFunctionColumn))
      .map((c) => c.id);
  }

  isString(type: any): type is string {
    return typeof type === 'string';
  }

  updateSortDirection(direction: 1 | -1) {
    this.sortDirection.update(() => direction);
    this.sortChange.emit({
      column: this.sortColumn(),
      direction: this.sortDirection()
    });
  }

  updateSortColumn(column: string | null) {
    this.sortColumn.update(() => column);
    this.sortChange.emit({
      column: this.sortColumn(),
      direction: this.sortDirection()
    });
  }

  updatePagination(event: TuiTablePagination): void {
    this.page.update(() => event.page);
    this.pageSize.update(() => event.size);
    this.paginationChange.emit(event);
  }

  updateHoveredItem(item: T, hovered: boolean) {
    if (hovered) {
      this.hoveredItem.update(() => item);
    } else {
      this.hoveredItem.update(() => null);
    }
  }

  folderSave() {
    const currentFilter = this._filter();
    if (currentFilter) {
      // TODO: dirty hack for BPHG extensions
      if (!this.folderCreate.observed) {
        this.folderService.saveFolder(currentFilter);
      }
      this.folderCreate.emit(currentFilter);
    }
  }

  updateCurrentFolderFilter() {
    const currentFilter = this._filter();
    const currentFolderId = this.activeFolderId();
    if (currentFilter && currentFolderId) {
      this.folderService.updateFolderFilter(currentFolderId, currentFilter);
    }
  }

  editFolder(folder: ExpandedFolderDto) {
    // TODO: dirty hack for BPHG extensions
    if (!this.folderEdit.observed) {
      this.folderService.editFolder(folder);
    }
    this.folderEdit.emit(folder);
  }

  removeFolder(folder: ExpandedFolderDto) {
    if (!this.folderRemove.observed) {
      this.folderService.removeFolder(folder);
    }
    this.folderRemove.emit(folder);
    if (folder.id === this.activeFolderId()) {
      this.activeFolderId.update(() => null);
    }
  }

  copyFolder(folder: FolderDto) {
    this.folderService.copyFolder(folder);
  }

  updateSelectedFolder(selectedFolder: ExpandedFolderDto) {
    this.activeFolderId.update(() => selectedFolder.id);
    this._filter.update(() =>
      injectObjectParameters(
        selectedFolder.filter.query,
        selectedFolder.filter.parameters
      )
    );
    this.filterChange.emit(this._filter());
    this.folderChange.emit(selectedFolder);
  }

  updateFilter(appliedFilter: Record<string, any> | null): void {
    this._filter.update(() => appliedFilter);
    this.filterChange.emit(appliedFilter);
  }

  rowClicked(row: T): void {
    this.entityClicked.emit(row);
  }

  resetFilter() {
    this._filter.update(() => null);
    this.activeFolderId.update(() => null);
    this.filterChange.emit(null);
  }

  updateFolderSearch(search: string) {
    this.folderService.nameSearch.update(() => search);
  }

  onScrollToEnd() {
    if (this.paginationMode === 'infinite') {
      this.page.update((page) => page + 1);
      this.paginationChange.emit({ page: this.page(), size: this.pageSize() });
    }
  }
}
